import Accordion from './accordion'
import Carousel from './carousel'

export default class LocationAccordion {
  constructor($el) {
    this.$el = $($el)

    new Accordion(this.$el)
    const carouselItem = this.$el.next().find('.js-location-carousel')
    const carousel = new Carousel(carouselItem)

    this.$el.on('click', () => {
      if (carousel.$el.length) {
        setTimeout(() => {
          $(carousel.$el)
            .get(0)
            .slick.setPosition()
        }, 250)
      }
    })
  }
}
