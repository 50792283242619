import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Mq from '../util/mq'

export default class MobileNav {
  constructor($el) {
    this.$el = $el
    this.activeClass = 'is-mobile-nav-open'
    this.isOpen = false

    const $trigger = $('.js-mobile-nav-trigger')
    $trigger.on('click', e => {
      e.preventDefault()

      if (this.isOpen) {
        this.closeNav()
      } else {
        this.openNav()
      }
    })

    Mq.atLeast('--site-nav-breakpoint', e => {
      if (e.matches) {
        this.closeNav()
      }
    })

    $(this.$el)
      .find('li')
      .each((index, element) => {
        if ($(element).find('ul').length) {
          $(element).addClass('has-dropdown')
        }
      })
  }

  openNav() {
    this.isOpen = true
    $('html').addClass(this.activeClass)
    disableBodyScroll(this.$el)
  }

  closeNav() {
    this.isOpen = false
    $('html').removeClass(this.activeClass)
    enableBodyScroll(this.$el)
  }
}
