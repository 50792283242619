export default class Collapse {
  constructor($el) {
    this.$el = $($el)
    this.animationSpeed = 200

    this.$el.on('click', e => {
      e.preventDefault()

      let target
      if ($(e.currentTarget).attr('data-target')) {
        target = $(e.currentTarget).attr('data-target')
      } else if ($(e.currentTarget).attr('href')) {
        target = $(e.currentTarget).attr('href')
      }

      $(target).slideUp(this.animationSpeed)
    })
  }
}
