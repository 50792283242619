import scrollIntoView from 'scroll-into-view'

export default class ScrollTo {
  constructor($el) {
    this.$el = $($el)
    this.target = this.$el.attr('href')
    
    this.$el.on('click', e => {
      e.preventDefault()

      scrollIntoView($(this.target)[0], {
        align: {
          top: 0,
          topOffset: $('.c-site-head').outerHeight() + 30,
        },
      })
    })
  }
}
