import Mq from '../util/mq'

export default class Carousel {
  constructor($el) {
    this.$el = $($el)

    const options = {
      accessibility: false,
      slidesToShow: 5,

      responsive: [
        {
          breakpoint: Mq.get('--xl'),
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: Mq.get('--lg'),
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: Mq.get('--md'),
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '10%',
            slidesToShow: 2,
          },
        },
        {
          breakpoint: Mq.get('--sm'),
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '10%',
            slidesToShow: 1,
          },
        },
      ],
    }

    this.$el.slick(options)
  }
}
