import Lity from 'lity'
import Cookies from 'cookies-js'

export default class Announcement {
  constructor($el) {
    this.$el = $($el)
    this.delay = this.$el.data('delay')

    setTimeout(() => {
      //Test if this is a new session.  Note that only fully exiting the browser will cause a new session, closing the tab or window will not
      if(Cookies('kimball-session') === undefined){
        Lity(this.$el);
        Cookies.set('kimball-session',true);
      }  
    }, this.delay)
  }
}
