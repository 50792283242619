import 'intersection-observer'
IntersectionObserver.prototype.POLL_INTERVAL = 100

// import external dependencies
import 'jquery'

// import local dependencies
import 'slick-carousel'
import 'lity'

import './modules'

import svg4everybody from 'svg4everybody'
svg4everybody()

import scrollDir from 'scrolldir'
scrollDir({ dir: 'up' })

import lozad from 'lozad'
const observer = lozad('.lozad', {
  rootMargin: '0px 0px 200px 0px',
})
observer.observe()

const closeBtn = '<button class="lity-custom-close" data-lity-close>close <span></span></button>'
$(document).on('lity:open', function() {
  $('.lity-container').append(closeBtn)
})

//Parse query string to scroll to activity
import queryString from 'query-string'
$(document).ready(function() {
  const parsed = queryString.parse(location.search)
  //Check for activity query var
  if (parsed.activity !== undefined) $('#' + parsed.activity).click()
})

//Convert Gravity Forms Legends to Div (Legend does not with Flexbox)
$('.j-form .gfield.show-label legend.gfield_label').each(function() {
  $(this).replaceWith('<div class="gfield_label">' + $(this).text().replace('(Required)', '') + '</label>');
});